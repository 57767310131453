/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(document).foundation(); // Foundation JavaScript
        fluidvids.init({
          selector: ['iframe', 'object'], // runs querySelectorAll()
          players: ['www.youtube.com', 'player.vimeo.com'] // players to support
        });

        // $('.iframeModal').on('click', function(){
        //   $id = $(this).data('open');
        //   if($(this).hasClass('youtube')) {
        //     var iframe_url = "https://www.youtube.com/embed/" + $id + "?autoplay=1&autohide=1";
        //   } else if ($(this).hasClass('vimeo')) {
        //     var iframe_url = "https://player.vimeo.com/video/" + $id + "?autoplay=1&title=0&byline=0&portrait=0 width='640' height='266' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen";
        //   }
        //   var $modal = $("#"+$id);
        //   var iframe = $('<iframe/>', {'frameborder': '0', 'src': iframe_url, 'width': 500, 'height': 500 });

         
        //     $.ajax()
        //       .done(function(resp){
        //        resp = iframe;
        //         $modal.html(resp).foundation('open');
        //     });         
        // });
      
        //clear the modal so video stops playing
        // $(window).on('closed.zf.reveal', function () {
        //   $('.reveal').html('<h2>Loading...</h2>');
        // });        

          Foundation.onImagesLoaded($('.grid img'), function(){
          // init Isotope after all images have loaded
          $grid = $('.grid').isotope({
            itemSelector: '.filter-item',
            percentPosition: true,
            animationEngine: 'best-available',
            layoutMode: 'fitRows',
            fitRows: {
              // gutter: 10
            }
          });
        });
     

        // filter items on button click
        $('.filter-button-group').on( 'click', 'button', function() {
          $this = $(this)
          $this.parent().parent().find('button').removeClass('active');
          var filterValue = $this.attr('data-filter');
          $grid.isotope({ filter: filterValue });
          $this.addClass('active');
        });

       
        // wait for all images to load before showing them.
        $('.teamImg .before, .teamImg .after').waitForImages({
            finished: function() {
              $('.brandPhoto .inner').css({'opacity': 1});
            },
            each: function() {
               // nothing for each but it must be here to work
            },
            waitForAll: true
        });
   

       
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      //youtube embed
      var youtubeEmbed = $(function() {
          $(".youtube").each(function() {
              // Based on the YouTube ID, we can easily find the thumbnail image
              // $(this).css({
              //   'background-image': 'url(http://i.ytimg.com/vi/' + this.id + '/maxresdefault.jpg)',
              //   'background-size': 'contain'
              // });
          
              // Overlay the Play icon to make it look like a video player
              $(this).prepend($('<div/>', {'class': 'play'}));
          
              $(document).delegate('#'+this.id, 'click', function() {
                  // Create an iFrame with autoplay set to true
                  var iframe_url = "https://www.youtube.com/embed/" + this.id + "?autoplay=1&autohide=1";
                  if ($(this).data('params')){ iframe_url+='&'+$(this).data('params');}
          
                  // The height and width of the iFrame should be the same as parent
                  var iframe = $('<iframe/>', {'frameborder': '0', 'src': iframe_url, 'width': $(this).width(), 'height': $(this).height() });
          
                  // Replace the YouTube thumbnail with YouTube HTML5 Player
                  $(this).replaceWith(iframe);
              });
          });
       });
      var vimeoEmbed = $(function() {
          $(".vimeo").each(function() {            
              // Overlay the Play icon to make it look like a video player
              $(this).prepend($('<div/>', {'class': 'play'}));
          
              $(document).delegate('#'+this.id, 'click', function() {
                  // Create an iFrame with autoplay set to true
                  var iframe_url = "https://player.vimeo.com/video/" + this.id + "?autoplay=1&title=0&byline=0&portrait=0 width='640' height='266' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen";
                  if ($(this).data('params')){ iframe_url+='&'+$(this).data('params');}
          
                  // The height and width of the iFrame should be the same as parent
                  var iframe = $('<iframe/>', {'frameborder': '0', 'src': iframe_url, 'width': $(this).width(), 'height': $(this).height() });
          
                  // Replace the thumbnail with  HTML5 Player
                  $(this).replaceWith(iframe);
              });
          });
       });

             
               

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
